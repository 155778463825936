.book {
    color: white;
    font-size: calc(10px + 1.8vmin);
    font-weight: bold;
    margin: 5px;
}

a {
    text-decoration: none;
}

.author {
    font-size: calc(10px + 0.8vmin);
    color: white;
}

ul {
    padding: 0 5% 0.1% 5%;
    margin-bottom: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

li {
    list-style: none;
    width: 100%;
    border-radius: 15px;
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: rgb(69, 69, 69);
    padding: 4%;
    margin: 2.5%;
    flex-grow: 1;
}

.further-reading {
    width: 100%;
    flex: auto;
    justify-content: center;
}

.book, .author {
    margin: 0;
}

.book-info {
    flex-grow: 9;
}

.reading-heading {
    font-size: calc(10px + 1.5vmin);
    padding: 3%;
}

@media only screen and (min-width: 600px) {
    li {
        width: 40%;
        padding: 3%;
        margin: 2%;
    }

    .reading-heading {
        padding: 3%;
    }

    .further-reading {
        padding: 0;
    }
}