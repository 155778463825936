.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  background-color: black;
}

.App-header {
  min-height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1.5vmin);
  color: white;
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0 0 0;
}

.price-index {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  flex-grow: 1;
}

@media only screen and (min-width: 600px) {
  main {
    width: 100%;
  }
}