.graph {
    width: 100%;
    margin: 5% 0;
}

@media only screen and (min-width: 600px) {
    .graph {
        height: 50vh;
        /* width: 100vh; */
        display: flex;
        justify-content: center;
        align-items: center;
        /* background-color: rgb(46, 46, 46); */
        border-radius: 20px;
    }
}